import React from 'react';
import PropTypes from 'prop-types';
import {Components} from "../data/Components";

SectionWIthImageBackground.propTypes = {
    imagePath : PropTypes.string
};

SectionWIthImageBackground.defaultProps = {
    imagePath : null
};


function SectionWIthImageBackground({imagePath, component}) {
    var backGroundImageStyle = {
        backgroundImage: "url('" + imagePath  + "')"
    }

    if (typeof Components[component] !== "undefined") {
        if (imagePath) {
            return (
                <div className={"background-image-section"} style={backGroundImageStyle}>
                    {React.createElement(Components[component])}
                </div>
            );
        } else {
            return (
                <div>
                    {React.createElement(Components[component])}
                </div>
            );
        }
    } else {
        if (imagePath) {
            return (
                <div className={"background-image-section"} style={backGroundImageStyle}>
                </div>
            );
        } else {
            return (
                <div>
                </div>
            );
        }
    }

}

export default SectionWIthImageBackground;