import React from 'react';
import {Row, Col} from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import Invitation from "../../resources/images/invitation.png";
import Details from "../../resources/images/invitation_details.png"

function About () {
    return (
        <div id='about'>
            <Fade up>
                <Row className={'section m-0'}>
                    <Col className={'centre d-none d-lg-inline'}>
                        <img src={Invitation} alt={'Invitation'} className={'section-image'}/>
                    </Col>
                    <Col className={'centre'}>
                        <img src={Details} alt={'Details'} className={'section-image'}/>
                    </Col>
                </Row>
            </Fade>
        </div>
    );
}

export default About;