import React from 'react';
import Loading from "../resources/images/loading.gif"

function Loader() {
    return (
        <span className={"centre"}>
            <img src={Loading} alt="Loading" className={"centre"}></img>
        </span>
    );
}

export default Loader;


