import React from 'react';
import {Footer} from "rsuite";
import {Col, Row} from "react-bootstrap";

function CustomFooter(props) {
    return (
        <>
          <Footer className={"centre"}>
              <Row>
                  <Col>
                      <p>Contact us at <a href="mailto:mikejen2022@gmail.com" target={"_blank"} rel={"noreferrer"}>mikejen2022@gmail.com</a></p>
                  </Col>
                  <Col>
                      <p>Jump to the <a href="/" target={"_blank"} rel={"noreferrer"}>Main Website</a></p>
                  </Col>
              </Row>
          </Footer>
        </>
    );
}

export default CustomFooter;