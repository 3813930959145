import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import {GiStarShuriken} from 'react-icons/gi'
import {Fade, Zoom} from "react-reveal";

class Travel extends Component {
    render() {
        return (
            <div id='travel'>
                    <Row className={'section m-0'}>
                        <Fade up>
                            <Row className={"travel-info centre"}>
                                <p className={"bordered"}>
                                    <span className={"d-none d-md-inline"}>
                                        <GiStarShuriken className={"iconStyle"} size={12}/>
                                        Transport from the ceremony to the reception will be provided but please indicate in your RSVP whether you will be taking this transport.
                                        <br/>
                                        <GiStarShuriken className={"iconStyle"} size={12}/>
                                        Coming from outside of Johannesburg? The nearest airport to both locations is Lanseria Airport.
                                        <br/>
                                        <GiStarShuriken className={"iconStyle"} size={12}/>
                                        Please don’t drink and drive! Uber does come to Lieu De Grace.
                                    </span>
                                    <span className={"d-md-none"}>
                                        <p>
                                            <GiStarShuriken className={"iconStyle"} size={12}/>
                                            Transport from the ceremony to the reception will be provided but please indicate in your RSVP whether you will be taking this transport.
                                        </p>
                                        <p>
                                            <GiStarShuriken className={"iconStyle"} size={12}/>
                                            Coming from outside of Johannesburg? The nearest airport to both locations is Lanseria Airport.
                                        </p>
                                        <p>
                                            <GiStarShuriken className={"iconStyle"} size={12}/>
                                            Please don’t drink and drive! Uber does come to Lieu De Grace.
                                        </p>
                                    </span>
                                </p>
                            </Row>
                        </Fade>
                        <Row className={"centre d-none d-lg-flex"}>
                            <Col xs={6}>
                                <Zoom right>
                                    <div className={"map-outer"}>
                                        <h2>Bryanston Catholic Church</h2>
                                        <div className={"map"}>
                                            <iframe title={"church-map"} className={"shadow map-inner"} src="https://maps.google.com/maps?q=18%20sloane%20street,%20bryanston&t=&z=13&ie=UTF8&iwloc=&output=embed"></iframe>
                                        </div>
                                    </div>
                                </Zoom>
                            </Col>
                            <Col xs={6}>
                                <Zoom left>
                                    <div className={"map-outer"}>
                                        <h2>Lieu De Grace</h2>
                                        <div className={"map"}>
                                            <iframe title={"venue-map"} className={"map-inner shadow"} src="https://maps.google.com/maps?q=172%20college%20road,%20Krugersdorp&t=&z=13&ie=UTF8&iwloc=&output=embed"></iframe>
                                        </div>
                                    </div>
                                </Zoom>
                            </Col>
                        </Row>
                    </Row>
            </div>
        );
    }
}

export default Travel;