let baseRsvpApiUrl = 'https://jen-mike-wedding-rsvp-api.herokuapp.com';
let postHeaders = {
    'Content-type' : 'application/json'
}

const getRsvpGroup = async (guid) => {
    const url = `${baseRsvpApiUrl}/groups/${guid}`;
    console.log(url);
    const groupResults = await fetch(url);
    const groupData =  await groupResults.json();
    return groupData;
}

const getUrlGuidByEmailAddress = async (emailAddress) => {
    const url = `${baseRsvpApiUrl}/urlGuid/${emailAddress}`;
    console.log(`Fetching guid for ${emailAddress}`)

    const result = await fetch(url);
    const status = result.status;
    const resultData =  await result.json();


    console.log(`Guid for ${emailAddress} is ${resultData.urlGuid}`)

    return {"urlGuid": resultData.urlGuid, "responseStatus": status};
}

const getRsvpInformation = async () => {
    const url = `${baseRsvpApiUrl}/thispageisforjennadontaskaboutit`;
    const rsvpResults = await fetch(url);
    const rsvpData =  await rsvpResults.json();

    console.log(rsvpData);

    return {
        "rsvpInformation": rsvpData.rsvpInformation,
        "yesCount": rsvpData.yesCount,
        "noCount": rsvpData.noCount,
        "noResponseCount": rsvpData.noResponseCount,
        "needTransportCount": rsvpData.needTransportCount
    };
}

async function getRsvp(guestId){
    const url = `${baseRsvpApiUrl}/rsvp?guestId=${guestId}`
    const rsvpResults = await fetch(url);
    const rsvpData =  await rsvpResults.json();

    const rsvpResponse = {
        "DietaryRequirements": rsvpData.dietary_requirments,
        "RsvpStatusId": rsvpData.rsvp_status_id,
        "GuestId": rsvpData.guest_id,
        "NeedsTransportFromChurch": rsvpData.needs_transport_from_church
    }

    return rsvpResponse;
}

async function postRsvp (dietaryRequirements, rsvpStatusId, guestId, needsTransportFromChurch) {
    const url = `${baseRsvpApiUrl}/rsvp`
    const rsvp = {
        "DietaryRequirements": dietaryRequirements,
        "RsvpStatusId": rsvpStatusId,
        "GuestId": guestId,
        "NeedsTransportFromChurch": needsTransportFromChurch
    }

    const result = await fetch(url,
        {
                method: "POST",
                headers: postHeaders,
                body: JSON.stringify(rsvp)
        });
    console.log(result)

    const rsvpResponse = await getRsvp(guestId);

    return rsvpResponse;
}

export{getRsvpGroup, postRsvp, getUrlGuidByEmailAddress, getRsvpInformation}