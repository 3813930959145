import React from 'react';
import Fade from 'react-reveal/Fade';
import RsvpEmail from "../RsvpPage/RsvpEmail";

function RsvpSection () {
    return (
        <div id='rsvp'>
                <div className={'section m-0'}>
                    <Fade up>
                        <h1 className={"centre"}>Access your RSVP</h1>
                        <RsvpEmail/>
                    </Fade>
                </div>
        </div>
    );
}

export default RsvpSection;