import React from 'react';
import {Row, Col} from 'react-bootstrap';
import PropTypes from "prop-types";
import Fade from 'react-reveal/Fade';

Welcome.propTypes = {
    imagePath : PropTypes.string
};

Welcome.defaultProps = {
    imagePath : null
};

function Welcome ({imagePath}) {
    return (
        <div className={"background-secondary"}>
            <div id='welcome' >
                <Fade>
                    <Row className={'section-welcome m-0'}>
                        <Col className={'centre'}>
                            <div className={"bordered"}>
                                <div className={'d-lg-none'}>
                                    <p>
                                        Welcome to Jen and Mike’s wedding website, we can’t wait to celebrate with you!
                                    </p>
                                    <p>
                                        We have created this website as a convenient way to share all the information you might need about our wedding day and the lead up to it.
                                    </p>
                                    <p>
                                        This is your one stop to find out any information! Look around and don’t forget to RSVP.
                                    </p>
                                </div>
                                <div className={'d-none d-lg-inline'}>
                                    <p >
                                        Welcome to Jen and Mike’s wedding website, we can’t wait to celebrate with you!<br/>
                                        We have created this website as a convenient way to share all the information you might need about our wedding day and the lead up to it.<br/>
                                        This is your one stop to find out any information! Look around and don’t forget to RSVP.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Fade>
            </div>
        </div>
    );
}

export default Welcome;