import React, {useEffect, useState} from 'react';
import {getRsvpInformation} from "../../services/RsvpService";
import {Container, Table} from "react-bootstrap";
import {AiOutlineCheckCircle, AiOutlineCloseCircle, MdOutlinePending} from "react-icons/all";
import {Progress} from "rsuite";

const getDateFromString = (dateString) => {
    const defaultDate = new Date(1970, 1, 1);

    if (!dateString) {
        return defaultDate;
    }

    const dateParts = dateString.split("/");
    const date = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);

    return date;
};

function RsvpInfo(props) {
    const [rsvpInfoData, setRsvpInfoData] = useState([]);
    const [yesCount, setYesCount] = useState(0);
    const [noCount, setNoCount] = useState(0);
    const [noResponseCount, setNoResponseCount] = useState(0);
    const [needTransportCount, setNeedTransportCount] = useState(0);
    const [yesPercent, setYesPercent] = useState(0);
    const [noPercent, setNoPercent] = useState(0);
    const [notRespondedPercent, setNotRespondedPercent] = useState(0);
    const getUrl = window.location;
    const baseUrl = getUrl.protocol + "//" + getUrl.host;

    useEffect(() => {
        function compareRsvpOnRsvpDate( a, b ) {

            if ( getDateFromString(a.rsvp_date) < getDateFromString(b.rsvp_date) ){
                return 1;
            }
            if ( getDateFromString(a.rsvp_date) > getDateFromString(b.rsvp_date) ){
                return -1;
            }
            return 0;
        }

        const fetchRsvpData = async () => {
            const fetchedData = await getRsvpInformation();
            const totalCount = fetchedData.rsvpInformation.length;

            fetchedData.rsvpInformation.sort(compareRsvpOnRsvpDate);
            setRsvpInfoData(fetchedData.rsvpInformation);
            setNoCount(fetchedData.noCount);
            setYesCount(fetchedData.yesCount);
            setNoResponseCount(fetchedData.noResponseCount);
            setNeedTransportCount(fetchedData.needTransportCount);

            setYesPercent((yesCount / totalCount) * 100);
            setNoPercent((noCount / totalCount) * 100);
            setNotRespondedPercent((noResponseCount / totalCount) * 100);
        }
        fetchRsvpData().then(() => {console.log("Rsvp data fetched from server")});
    }, [yesCount, noCount, noResponseCount]);

    const yesStyle = {
        color: "green"
    };

    const noStyle = {
        color: "red"
    };

    const pendingStyle = {
        color: "black"
    };

    const dateDifferenceInDays = (dateString) => {
        const today = new Date();
        const date = getDateFromString(dateString)

        return Math.round((today-date)/(1000*60*60*24));
    };

    const getRowClass = (dateString) => {
        if (!dateString) {
            return "";
        }

        const difference = dateDifferenceInDays(dateString);

        if (difference <= 1) {
            return "recent-row";
        } else if (difference <= 2) {
            return "medium-recent-row";
        } else {
            return "";
        }
    }

    return (
        <Container>
            <div className={"p-2"}>
                Responded: {noCount + yesCount}
                <br/>
                Not Responded: {noResponseCount}
                <br/>
                Yes: {yesCount}
                <br/>
                No: {noCount}
                <br/>
                Need a Lift: {needTransportCount}
            </div>
            <div>
                <Progress.Line percent={Math.trunc(100 - notRespondedPercent)} strokeColor={"blue"} />
                <Progress.Line percent={noPercent} status="fail" />
                <Progress.Line percent={yesPercent} status="success" />
            </div>
            <Table  striped bordered hover>
                <thead>
                    <tr>
                        <th className={"d-none d-lg-table-cell"}>GuestId</th>
                        <th>Name</th>
                        <th>Group</th>
                        <th>Rsvp</th>
                        <th>Needs Transport</th>
                        <th className={"d-none d-lg-table-cell"}>Rsvp Date</th>
                        <th className={"d-none d-lg-table-cell"}>Dietary Restrictions</th>
                        <th className={"d-none d-lg-table-cell"}>RSVP Link</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        rsvpInfoData.map((rsvp, index) => {
                            return(
                                <tr className={getRowClass(rsvp.rsvp_date)}>
                                    <td className={"d-none d-lg-table-cell"}>{rsvp.guest_id}</td>
                                    <td>{rsvp.name}</td>
                                    <td>{rsvp.group_description}</td>
                                    <td>{rsvp.rsvp_status === "Yes" ?
                                        <AiOutlineCheckCircle style={yesStyle} size={25}/>
                                        : rsvp.rsvp_status === "No" ?
                                            <AiOutlineCloseCircle style={noStyle} size={25}/>
                                        : <MdOutlinePending style={pendingStyle} size={25}/>
                                    }</td>
                                    <td>{rsvp.needs_transport_from_church === "Yes" ?
                                        <AiOutlineCheckCircle style={yesStyle} size={25}/>
                                        : <AiOutlineCloseCircle style={noStyle} size={25}/>
                                    }</td>
                                    <td className={"d-none d-lg-table-cell"}>{rsvp.rsvp_date}</td>
                                    <td className={"d-none d-lg-table-cell"}>{rsvp.food_constraints}</td>
                                    <td className={"d-none d-lg-table-cell"}>{baseUrl}/rsvp/{rsvp.url_guid}</td>
                                </tr>
                            );
                        })
                    }

                </tbody>
            </Table>
            </Container>
    );
}

export default RsvpInfo;