import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import Glare from '../../resources/images/glare_look_colour.jpg'
import Fade from 'react-reveal/Fade';

class Registry extends Component {
    render() {
        return (
            <div id='registry'>
                <Fade up>
                    <Row className={'section m-0'}>
                        <Col className={'centre d-none d-lg-inline'}>
                            <img src={Glare} alt={'Pretty of Jen and Mike'} className={'section-image window-image'}/>
                        </Col>
                        <Col className={'centre'}>
                            <h2 className={"d-sm-none bordered"}>
                                We are already blessed with much love and laughter having you all in our lives, so gifts are not
                                necessary! However, if you wish to give us a gift you can visit our registry <a className={"link-style"} href={"https://yuppiechef.com/yc6088420"} rel="noreferrer" target={"_blank"}>
                                here</a>.
                            </h2>
                            <h1 className={"registry-block d-none d-sm-inline bordered"}>
                                We are already blessed with much love and laughter having you all in our lives, so gifts are not
                                necessary! However, if you wish to give us a gift you can visit our registry <a className={"link-style"} href={"https://yuppiechef.com/yc6088420"} rel="noreferrer" target={"_blank"}>
                                here</a>.
                            </h1>
                        </Col>
                    </Row>
                </Fade>
            </div>
        );
    }
}

export default Registry;