import React, {useEffect, useState} from "react";
import {OverlayTrigger, Row} from "react-bootstrap";
import {Button, Form, Input, Radio, RadioGroup} from "rsuite";
import {AiOutlineQuestionCircle} from "react-icons/ai"
import {Tooltip} from "react-bootstrap";
import PropTypes from "prop-types";
import {RsvpStatusIdEnum} from "../../data/Enums";

RsvpForm.protoTypes = {
    onSubmit: PropTypes.func.isRequired,
    guestId: PropTypes.number.isRequired,
    hasSubmitted: PropTypes.bool,
    isComingResult: PropTypes.bool,
    dietaryRestrictionsResult: PropTypes.string,
    needsLiftResult: PropTypes.bool
}

RsvpForm.defaultProps = {
    hasSubmitted: false,
    isComingResult: false,
    dietaryRestrictionsResult: '',
    needsLiftResult: false
}

function RsvpForm({onSubmit, hasSubmittedInput, isComingResult, dietaryRestrictionsResult, needsLiftResult, guestId}) {
    const [isComing, setIsComing] = useState(isComingResult? "yes" : "no");
    const [dietaryRestrictions, setDietaryRestrictions] = useState(dietaryRestrictionsResult);
    const [needsLift, setNeedsLift] = useState(needsLiftResult? "yes" : "no");
    const [isFormEnabled, setIsFormEnabled] = useState(true);
    const [modelError, setModelError] = useState('');
    const [hasSubmitted, setHasSubmitted] = useState(hasSubmittedInput);

    useEffect(() => {
        setIsFormEnabled(!hasSubmitted);

        if (hasSubmitted) {
            setModelError("You have successfully RSVP'd. If you would like to modify your RSVP, please get a hold of Mike or Jen.");
        }
    }, [hasSubmitted]);

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            The venue is around half an hour away, with plentiful parking
        </Tooltip>
    );

    const submitForm = (f) => {
        f.preventDefault();
        if(isFormEnabled){
            const rsvpStatusId = isComing === "yes" ? RsvpStatusIdEnum.Yes : RsvpStatusIdEnum.No;
            const needsTransportFromChurch = needsLift === "yes";
            setHasSubmitted(true);
            onSubmit(dietaryRestrictions, rsvpStatusId, guestId, needsTransportFromChurch)
                .then((rsvpResponse) => {
                    console.log(rsvpResponse);

                    setDietaryRestrictions(rsvpResponse.DietaryRequirements);
                    console.log(rsvpResponse.RsvpStatusId, RsvpStatusIdEnum.Yes);
                    setIsComing(rsvpResponse.RsvpStatusId === RsvpStatusIdEnum.Yes ? "yes" : "no");
                    setNeedsLift(rsvpResponse.NeedsTransportFromChurch ? "yes" : "no");

                }).catch(() => {
                    setHasSubmitted(false);
            });
        } else {
            setModelError("You cannot RSVP more than once. If you have not yet RSVP'd and are seeing this message, then please drop us an email at mikejen2022@gmail.com. (Or Whatsapp Jen or Mike)");
        }
    };

    return(
        <div>
            <div
                id={'submit-error'}
                className={'error-block'}
                hidden={!modelError}
            >
                <p>{modelError}</p>
            </div>
            <Form className={'rsvp-form'}>
                <Row>
                    <h2>Will you be attending the wedding?</h2>
                    <RadioGroup
                        inline
                        name="isComing"
                        value={isComing}
                        onChange={e => {setIsComing(e)}}
                        disabled={!isFormEnabled}
                    >
                        <Radio className={"radio"} value="yes" disabled={!isFormEnabled} >Of course!</Radio>
                        <Radio value="no" disabled={!isFormEnabled} >Unfortunately not</Radio>
                    </RadioGroup>
                </Row>

                <Row>
                    <h2>
                        Will you be using the shuttle from the church to the venue?
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}
                            >
                            <AiOutlineQuestionCircle className={"iconStyle"} size={15}/>
                        </OverlayTrigger>
                    </h2>
                    <RadioGroup
                        inline
                        name="needsLift"
                        value={needsLift}
                        onChange={e => {setNeedsLift(e)}}
                        disabled={!isFormEnabled}
                    >
                        <Radio value="yes" disabled={!isFormEnabled} >Yes, Please!</Radio>
                        <Radio value="no" disabled={!isFormEnabled} >No, I will drive or Uber</Radio>
                    </RadioGroup>
                </Row>
                <Row>
                    <h2>Do you have any dietary restrictions?</h2>
                    <Input as="textarea"
                           placeholder={dietaryRestrictions}
                           onChange={e => setDietaryRestrictions(e)}
                           disabled={!isFormEnabled}
                    />
                </Row>
                <Row className={"centre p-3"}>
                    <Button
                        className={"submitButton centre shadow"}
                        onClick={e => submitForm(e)} disabled={!isFormEnabled}
                    >
                        <h1>RSVP</h1>
                    </Button>
                </Row>
            </Form>
        </div>
    );
}

export default RsvpForm;