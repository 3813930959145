import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {getRsvpGroup, postRsvp} from "../../services/RsvpService";
import './Rsvp.sass';
import {Col, Container, Row} from "react-bootstrap";
import RsvpForm from "./RsvpForm";
import {GiStarShuriken} from "react-icons/gi";
import {RsvpStatusIdEnum} from "../../data/Enums";
import Loader from "../Loader";
import Fade from "react-reveal";

function Rsvp() {
    let {guid} = useParams();

    const [groupId, setGroupId] = useState(-1);
    const [groupDescription, setGroupDescription] = useState('No Group');
    const [guests, setGuests] = useState([]);
    const [modelError, setModelError] = useState('');

    const onSubmit = async (dietaryRequirements, rsvpStatusId, guestId, needsTransportFromChurch) => {
        const responseSuccessResult = await postRsvp(dietaryRequirements, rsvpStatusId, guestId, needsTransportFromChurch);
        console.log(responseSuccessResult);
        if (responseSuccessResult) {
            return responseSuccessResult;
        } else {
            window.scrollTo({top: 0, behavior: 'smooth'});
            setModelError("There was an error processing your RSVP Please try again later, or contact Mike or Jen if the error persists.");
        }
    }

    useEffect(() => {
        const fetchTasks = async () => {
            const fetchedGroup = await getRsvpGroup(guid);
            setGroupId(fetchedGroup.group_id);
            setGroupDescription(fetchedGroup.group_description)
            if (fetchedGroup.guests) {
                setGuests(fetchedGroup.guests);
            }
        }
        fetchTasks().then(() => {console.log("Group fetched from server")});
    }, [guid])

    return(
        <div>
            <Fade up>
                <Container>
                    {guests.length === 0 ?
                        <div>
                            <h1 className={"centre"}>Please wait while your RSVP is retrieved</h1>
                            <Loader/>
                        </div>
                        :
                        <Row className={"centre"}>
                            <h1>WELCOME {groupDescription.toUpperCase()}!</h1>
                            <p>
                                {
                                    guests.every((g) => {return g.rsvp_status_id !== RsvpStatusIdEnum.NotResponded}) ?
                                        'It would appear that there are no outstanding RSVPs for you' :
                                        groupId < 0 ? 'Please RSVP on the form below.' : 'Please RSVP for yourself and any other relevant members of your party on the form below.'
                                }
                            </p>
                            <h2 className={"important"}>
                                Check out all the important information on the main website <h1><Link className={"link-style button shadow p-2"} target="_blank" to="/">here</Link></h1>
                            </h2>
                        </Row>
                    }
                    <div
                        id={'submit-error'}
                        className={'error-block'}
                        hidden={!modelError}
                    >
                        <p>{modelError}</p>
                    </div>

                    {guests.map((guest) => (
                        <Row key={guest.guest_id}>
                            <Col sm={2} xs={0}>

                            </Col>
                            <Col>
                                <div className={"form-section shadow p-4 m-4"}>
                                    <Row className={"centre"}>
                                        <h2><GiStarShuriken className={"iconStyle white"}  size={17}/>{guest.name}<GiStarShuriken className={"iconStyle white"}  size={17}/></h2>
                                    </Row>
                                    <Row>
                                        <RsvpForm
                                            onSubmit={onSubmit}
                                            hasSubmittedInput={guest.rsvp_status_id !== RsvpStatusIdEnum.NotResponded}
                                            isComingResult={guest.rsvp_status_id === RsvpStatusIdEnum.Yes}
                                            dietaryRestrictionsResult={guest.food_constraints? guest.food_constraints : ''}
                                            needsLiftResult={guest.needs_transport_from_church}
                                            guestId={guest.guest_id}
                                        />
                                    </Row>
                                </div>
                            </Col>
                            <Col sm={2} xs={0}>

                            </Col>

                        </Row>
                    ))}
                </Container>
            </Fade>
        </div>
    )
}

export default Rsvp;