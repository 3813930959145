import About from "../components/SectionFillers/About";
import Welcome from "../components/Welcome";
import Travel from "../components/SectionFillers/Travel";
import Accommodation from "../components/SectionFillers/Accommodation";
import Registry from "../components/SectionFillers/Registry";
import RsvpSection from "../components/SectionFillers/RsvpSection";

export const Components = {
    About: About,
    Welcome: Welcome,
    Travel: Travel,
    Accommodation: Accommodation,
    Registry: Registry,
    RsvpSection: RsvpSection
}