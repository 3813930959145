import React from 'react';
import {menuData} from "../data/MenuData";
import {Link} from "react-scroll";
import {Row, Col, Dropdown} from 'react-bootstrap';
import {HiMenu} from 'react-icons/hi';


const Navigator = props => {

    const navRowStyle = {
        marginLeft: 0,
        marginRight: 0,
        paddingRight: 0,
        paddingLeft: 0
    }

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            className={"link-style"}
            href="/"
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            <HiMenu size={50}></HiMenu>
        </a>
    ));

    return (
        <div className={"sticky-nav"}>
            <div className={"d-none d-lg-inline"}>
                <Row style={navRowStyle}>
                    <Col ></Col>
                    {menuData.map((item, index) => {
                        return (
                            <Col className={"nav-element"}  key={index}>
                                <Link to={item.link} className={"link-style"} key={index}>
                                    <div className={"centre"}>
                                        <h1 className={"link-style"} key={index}>{item.title}</h1>
                                    </div>
                                </Link>
                            </Col>
                        )
                    })}
                    <Col></Col>
                </Row>
            </div>
            <div className={"d-lg-none"}>
                <Dropdown className={"pull-left sticky-nav-mobile"}>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">

                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {menuData.map((item, index) => {
                            return (
                                <Col key={index}>
                                    <Link to={item.link} className={"link-style"} key={index}>
                                        <div className={"centre"} key={index}>
                                            <h1 className={"link-style"} key={index}>{item.title}</h1>
                                        </div>
                                    </Link>
                                </Col>
                            )
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    );
};

Navigator.propTypes = {
    
};

export default Navigator;