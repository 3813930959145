export const menuData = [
    {
        'title':'Info',
        'link':'about'
    },
    {
        'title':'Travel',
        'link':'travel'
    },
    {
        'title':'Accommodation',
        'link':'accommodation'
    },
    {
        'title':'Registry',
        'link':'registry'
    },
    {
        'title':'RSVP',
        'link':'rsvp'
    }
]