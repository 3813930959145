import '../style/Header.css';
import React from 'react';
// import CurvedHeading from "./CurvedHeading";

Header.propTypes = {
    
};

Header.defaultProps = {

};

function Header(props) {

    return (
        <div id={'home'} className={'background-image header centre'}>
            {/*<div className={'wrapper'}>*/}
            {/*    <CurvedHeading text="Jenna And Michael" arc={130} radius={400} />*/}
            {/*    <h1>19 March 2021</h1>*/}
            {/*</div>*/}
        </div>
    );
}

export default Header;