import './Rsvp.sass';
import React, {useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Button, Form, Input} from "rsuite";
import {getUrlGuidByEmailAddress} from "../../services/RsvpService";
import validator from 'validator'

function RsvpEmail () {
    const [emailAddress, setEmailAddress] = useState('');
    const [modelError, setModelError] = useState('');

    const rsvpUrl = "/rsvp"

    const fetchGuid = async (e) => {
        console.log(emailAddress);

        if (!validator.isEmail(emailAddress)){
            setModelError("Please enter a valid email address.");
            return;
        } else {
            setModelError("");
        }

        try {
            getUrlGuidByEmailAddress(emailAddress)
                .then((result) => {
                if (result.responseStatus === 200 && result.urlGuid !== '') {
                    console.log(result)
                    const redirectUrl = `${rsvpUrl}/${result.urlGuid}`
                    console.log(redirectUrl)
                    window.location = redirectUrl;
                } else if (result.responseStatus === 400) {
                    setModelError("The provided email address is either invalid or does not match an invitation. If this is incorrect, please then please drop us an email at mikejen2022@gmail.com. (Or Whatsapp Jen or Mike)");
                }
            }).catch((c) => {
                setModelError("An error occurred. If this message persists, please then please drop us an email at mikejen2022@gmail.com. (Or Whatsapp Jen or Mike)");
            });
        } catch (e) {
            setModelError("An error occurred. If this message persists, please then please drop us an email at mikejen2022@gmail.com. (Or Whatsapp Jen or Mike)");
        }
    };

    return(
        <>
            <Container>
                <Row>
                    <Col sm={2} xs={0}>
                    </Col>
                    <Col>
                        <div
                            id={'submit-error'}
                            className={'error-block mt-4'}
                            hidden={!modelError}
                        >
                            <p>{modelError}</p>
                        </div>
                        <div className={"form-section shadow p-4 m-4"}>
                            <Row className={"centre"}>
                                <h1>Please enter the email address that your invitation was sent to</h1>
                            </Row>
                            <Form onSubmit={(e) => fetchGuid(e)}>
                                <Row>
                                    <Input as="textarea"
                                           type="email"
                                           placeholder={emailAddress}
                                           onChange={e => setEmailAddress(e)}
                                    />
                                </Row>
                                <Row className={"centre p-3"}>
                                    <Button className={"submitButton centre shadow"} onClick={(e) => fetchGuid(e)}>
                                        <h1>Proceed</h1>
                                    </Button>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                    <Col sm={2} xs={0}>
                    </Col>
                </Row>

            </Container>
        </>);
}

export default RsvpEmail;