import React, {Component} from 'react';
import {Row, Col, Card} from 'react-bootstrap';
import {GiStarShuriken} from "react-icons/gi";
import Fade from 'react-reveal/Fade';

class Accommodation extends Component {
    render() {
        return (
            <div id='accommodation'>

                <Row className={'section m-0 p-1'}>
                    <Row xs={12} className={"centre m-0"}>
                        <Fade bottom>
                            <h3 className={"m-0 p-4 pb-0"}>
                                We know you want to party all night with us so here is some accommodation in the area if you’d like to stay (Airbnb also has some good options).
                            </h3>
                        </Fade>
                    </Row>
                    <div className={"d-none d-md-inline"}>
                        <Fade bottom cascade>
                            <Row className={"m-0"}>
                                <Col xs={4} className={"accommodation-col"}>
                                    <Card className={"accommodation-card shadow"}>
                                        <Card.Title><h3>Country Park Guests House</h3></Card.Title>
                                        <Card.Text>
                                            Approx. 6.5km from the venue
                                            <br/>
                                            Contact Elmarie 079 159 0594
                                        </Card.Text>
                                        <Card.Link className={"link-style card-link"}  rel="noreferrer" target={"_blank"}  href="https://www.countrypark.co.za">www.countrypark.co.za</Card.Link>
                                    </Card>
                                </Col>
                                <Col xs={4} className={"accommodation-col"}>
                                    <Card className={"accommodation-card shadow"}>
                                        <Card.Title><h3>Riverstone Lodge</h3></Card.Title>
                                        <Card.Text>
                                            Approx. 6.5km from the venue
                                            <br/>Contact Zandile on 011 668 3111
                                        </Card.Text>
                                        <Card.Link className={"link-style card-link"}  rel="noreferrer" target={"_blank"}  href="https://www.riverstonelodge.co.za/accommodation-in-muldersdrift/">www.riverstonelodge.co.za</Card.Link>
                                    </Card>
                                </Col>
                                <Col xs={4} className={"accommodation-col"}>
                                    <Card className={"accommodation-card shadow"}>
                                        <Card.Title><h3>Heartly Manor</h3></Card.Title>
                                        <Card.Text>
                                            Approx. 6.5km from the venue
                                            <br/>Contact on 082 548 0916
                                        </Card.Text>
                                        <Card.Link className={"link-style card-link"}  rel="noreferrer" target={"_blank"}  href="https://www.hartleymanor.co.za/">www.hartleymanor.co.za</Card.Link>
                                    </Card>
                                </Col>
                            </Row>
                        </Fade>
                        <Fade bottom cascade>
                            <Row className={"m-0"}>
                                <Col xs={4} className={"accommodation-col"}>
                                    <Card className={"accommodation-card shadow"}>
                                        <Card.Title><h3>Jackal Creek Golf Estate</h3></Card.Title>
                                        <Card.Text>
                                            Apartment on Secure Golf Estate in Northriding, Sleeps 4
                                        </Card.Text>
                                        <Card.Link className={"link-style card-link"}  rel="noreferrer" target={"_blank"}  href="https://abnb.me/DCkmtJERXU">Airbnb</Card.Link>
                                    </Card>
                                </Col>
                                <Col xs={4} className={"accommodation-col"}>
                                    <Card className={"accommodation-card shadow"}>
                                        <Card.Title><h3>Pride Aloe</h3></Card.Title>
                                        <Card.Text>
                                            Entire Residential Home
                                            <br/>
                                            Sleeps 12
                                        </Card.Text>
                                        <Card.Link className={"link-style card-link"}  rel="noreferrer" target={"_blank"}  href="https://www.airbnb.co.za/rooms/50862524?check_in=2022-03-19&check_out=2022-03-20&guests=1&adults=1&s=67&unique_share_id=a688f2b2-364e-44fd-9af8-47af417d232d&locale=en&_set_bev_on_new_domain=1608275113_ZWY3NWQ3ZmI0YmVi&source_impression_id=p3_1638905286_55sFwylRvfXUwBkw">
                                            Airbnb
                                        </Card.Link>
                                    </Card>
                                </Col>
                                <Col xs={4} className={"accommodation-col"}>
                                    <Card className={"accommodation-card shadow"}>
                                        <Card.Title><h3>Amadwala Lodge</h3></Card.Title>
                                        <Card.Text>
                                            Approx. 7.7km from the venue
                                            <br/>
                                            Contact on 083 641 1429
                                        </Card.Text>
                                        <Card.Link className={"link-style card-link"}  rel="noreferrer" target={"_blank"}  href="https://amadwalalodge.com/accommodation/">amadwalalodge.com</Card.Link>
                                    </Card>
                                </Col>
                            </Row>
                        </Fade>
                    </div>
                    <Fade bottom cascade>
                        <div className={"d-sm-none centre"}>
                            <p>
                                <GiStarShuriken className={"iconStyle"} size={12}/>
                                <a className={"link-style card-link small"} rel="noreferrer" target={"_blank"}  href="https://www.countrypark.co.za">www.countrypark.co.za</a>
                                <GiStarShuriken className={"iconStyle"} size={12}/>
                                <br/>
                                <GiStarShuriken className={"iconStyle"} size={12}/>
                                <a className={"link-style card-link small"}  rel="noreferrer" target={"_blank"}  href="https://www.riverstonelodge.co.za/accommodation-in-muldersdrift/">www.riverstonelodge.co.za</a>
                                <GiStarShuriken className={"iconStyle"} size={12}/>
                                <br/>
                                <GiStarShuriken className={"iconStyle"} size={12}/>
                                <a className={"link-style card-link small"}  rel="noreferrer" target={"_blank"}  href="https://www.hartleymanor.co.za/">www.hartleymanor.co.za</a>
                                <GiStarShuriken className={"iconStyle"} size={12}/>
                                <br/>
                                <GiStarShuriken className={"iconStyle"} size={12}/>
                                <a className={"link-style card-link small"}  rel="noreferrer" target={"_blank"}  href="https://abnb.me/DCkmtJERXU">Jackal Creek Golf Estate - Airbnb</a>
                                <GiStarShuriken className={"iconStyle"} size={12}/>
                                <br/>
                                <GiStarShuriken className={"iconStyle"} size={12}/>
                                <a className={"link-style card-link small"}  rel="noreferrer" target={"_blank"}  href="https://www.airbnb.co.za/rooms/50862524?check_in=2022-03-19&check_out=2022-03-20&guests=1&adults=1&s=67&unique_share_id=a688f2b2-364e-44fd-9af8-47af417d232d&locale=en&_set_bev_on_new_domain=1608275113_ZWY3NWQ3ZmI0YmVi&source_impression_id=p3_1638905286_55sFwylRvfXUwBkw">
                                    Pride Aloe - Airbnb
                                </a>
                                <GiStarShuriken className={"iconStyle"} size={12}/>
                                <br/>
                                <GiStarShuriken className={"iconStyle"} size={12}/>
                                <a className={"link-style card-link small"}  rel="noreferrer" target={"_blank"}  href="https://amadwalalodge.com/accommodation/">amadwalalodge.com</a>
                                <GiStarShuriken className={"iconStyle"} size={12}/>
                            </p>
                        </div>

                        <div className={"d-none d-sm-inline d-md-none centre"}>
                            <p>
                                <a className={"link-style card-link small"} rel="noreferrer" target={"_blank"}  href="https://www.countrypark.co.za">www.countrypark.co.za</a>
                                <br/>
                                <GiStarShuriken className={"iconStyle"} size={12}/>
                                <br/>
                                <a className={"link-style card-link small"}  rel="noreferrer" target={"_blank"}  href="https://www.riverstonelodge.co.za/accommodation-in-muldersdrift/">www.riverstonelodge.co.za</a>
                                <br/>
                                <GiStarShuriken className={"iconStyle"} size={12}/>
                                <br/>
                                <a className={"link-style card-link small"}  rel="noreferrer" target={"_blank"}  href="https://www.hartleymanor.co.za/">www.hartleymanor.co.za</a>
                                <br/>
                                <GiStarShuriken className={"iconStyle"} size={12}/>
                                <br/>
                                <a className={"link-style card-link small"}  rel="noreferrer" target={"_blank"}  href="https://abnb.me/DCkmtJERXU">Jackal Creek Golf Estate - Airbnb</a>
                                <br/>
                                <GiStarShuriken className={"iconStyle"} size={12}/>
                                <br/>
                                <a className={"link-style card-link small"}  rel="noreferrer" target={"_blank"}  href="https://www.airbnb.co.za/rooms/50862524?check_in=2022-03-19&check_out=2022-03-20&guests=1&adults=1&s=67&unique_share_id=a688f2b2-364e-44fd-9af8-47af417d232d&locale=en&_set_bev_on_new_domain=1608275113_ZWY3NWQ3ZmI0YmVi&source_impression_id=p3_1638905286_55sFwylRvfXUwBkw">
                                    Pride Aloe - Airbnb
                                </a>
                                <br/>
                                <GiStarShuriken className={"iconStyle"} size={12}/>
                                <br/>
                                <a className={"link-style card-link"}  rel="noreferrer" target={"_blank"}  href="https://amadwalalodge.com/accommodation/">amadwalalodge.com</a>
                            </p>
                        </div>
                    </Fade>

                </Row>
            </div>
        );
    }
}

export default Accommodation;