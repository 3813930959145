import './App.sass';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Header from "./components/Header";
import Welcome from "./components/Welcome";
import SectionWIthImageBackground from "./components/SectionWIthImageBackground";
import Bench from "./resources/images/bench.jpg";
import WalkColour from "./resources/images/walk_colour.jpg";
import Hands from "./resources/images/hands_colour.jpg";
import Background_1 from "./resources/images/fade_1.png";
import Background_2 from "./resources/images/fade_2.png";
import Background_3 from "./resources/images/fade_3.png";
import Background_4 from "./resources/images/fade_4.png";
import Titanic from "./resources/images/titanic.jpg";
import Navigator from "./components/Navigator";
import React from "react";
import Rsvp from "./components/RsvpPage/Rsvp";
import config from 'react-reveal/globals';
import RsvpEmail from "./components/RsvpPage/RsvpEmail";
import Glare from "./resources/images/glare_landscape_colour.jpg";
import Footer from "./components/CustomFooter";
import RsvpInfo from "./components/RsvpPage/RsvpInfo";

config({ ssrFadeout: true });

function App() {
  return (
    <Router>
        <Switch>

            <Route path={"/rsvp/:guid"}>
                <Rsvp/>
                <Footer/>
            </Route>
            <Route path={"/rsvp"}>
                <RsvpEmail/>
                <Footer/>
            </Route>
            <Route path={"/thispageisforjennadontaskquestions"}>
                <RsvpInfo/>
            </Route>
            <Route path={"/"}>
                <Header/>
                <Navigator/>
                <Welcome imagePath={Background_2}/>
                <SectionWIthImageBackground imagePath={Hands} />
                <SectionWIthImageBackground imagePath={Background_1} component={"About"} />
                <SectionWIthImageBackground imagePath={Titanic}/>
                <SectionWIthImageBackground imagePath={Background_2} component={"Travel"} />
                <SectionWIthImageBackground imagePath={Glare}/>
                <SectionWIthImageBackground imagePath={Background_4} component={"Accommodation"} />
                <SectionWIthImageBackground imagePath={Bench} />
                <SectionWIthImageBackground imagePath={Background_3} component={"Registry"} />
                <SectionWIthImageBackground imagePath={WalkColour} />
                <SectionWIthImageBackground imagePath={Background_1} component={"RsvpSection"} />
                <Footer/>
            </Route>
        </Switch>
    </Router>
  );
}

export default App;
